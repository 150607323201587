import React, { useEffect, useState } from 'react'
import { getStockHistory, getMarketHistory } from '../data/StockHistory'
import { useParams } from 'react-router';
import Header from '../components/Header';
import { LocalizationProvider, DatePicker } from '@mui/lab'
import DateAdapter from '@mui/lab/AdapterDayjs';
import { TextField, Grid, Chip, Typography, Paper, TableRow, TableCell, TableHead, Table, Box, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import './../styles/css/Backtest.css'
import Stock from '../components/Stock';
import { ExpandMore } from '@mui/icons-material';
import StockTable from '../components/StockTable';
import dayjs from 'dayjs'

const INITIAL_VALUE = 1000;

export default function Backtest() {
    const { stocks } = useParams();
    const tickers = stocks.split(',');
    const [date, setDate] = useState(null);
    const [history, setHistory] = useState([]);
    const [marketHistory, setMarketHistory] = useState(null);
    const [portfolioHistory, setPorfolioHistory] = useState({ symbol: 'Portfolio' });
    const [update, setUpdate] = useState(0);

    useEffect(() => {
        //getStockHistory('AAPL').then(data => console.log(data));
        getMarketHistory().then((data) => {
            setMarketHistory({ symbol: 'S&P', data })
        });
        tickers.forEach((symbol) => {
            getStockHistory(symbol).then((data) => {
                setHistory(prev => [...prev, { symbol, data }])
            });
        });
        return (
            setHistory([])
        )
    }, []);

    const handleDateChange = (value) => {
        setDate(value);
        let newDate = value.toDate();
        history.forEach(obj => {
            obj.periodEnd = obj.data.historical[0];
            obj.periodStart = obj.data.historical.filter(({ date }) => {
                date = new Date(date);
                return date.getDate() - newDate.getDate() <= 7 &&
                    date.getMonth() === newDate.getMonth() &&
                    date.getYear() === newDate.getYear();
            })
            if (obj.periodStart.length) {
                obj.periodStart = obj.periodStart[0];
                obj.percentChange = ((parseFloat(obj.periodEnd.close) / parseFloat(obj.periodStart.close) - 1) * 100);
            }
        });
        if (marketHistory) {
            marketHistory.periodEnd = marketHistory.data.historical[0];
            marketHistory.periodStart = marketHistory.data.historical.filter(({ date }) => {
                date = new Date(date);
                return date.getDate() - newDate.getDate() <= 7 &&
                    date.getMonth() === newDate.getMonth() &&
                    date.getYear() === newDate.getYear();
            })
            if (marketHistory.periodStart.length) {
                marketHistory.periodStart = marketHistory.periodStart[0];
                marketHistory.percentChange = ((parseFloat(marketHistory.periodEnd.close) / parseFloat(marketHistory.periodStart.close) - 1) * 100);
            }
        }
        if (history.length) {
            setPorfolioHistory(() => {
                let acc = [];
                history.forEach((stock) => {
                    if (!acc.length) {
                        acc = JSON.parse(JSON.stringify(stock.data.historical));
                        acc.forEach((o) => {
                            o.open = o.open / stock.periodStart.open;
                        })
                    } else {
                        stock.data.historical.forEach((o, i) => {
                            acc[i].open += o.open / stock.periodStart.open;
                        })
                    }
                }, []);
                portfolioHistory.periodEnd = acc[0]
                portfolioHistory.periodStart = acc.filter(({ date }) => {
                    date = new Date(date);
                    return date.getDate() - newDate.getDate() <= 7 &&
                        date.getMonth() === newDate.getMonth() &&
                        date.getYear() === newDate.getYear();
                })
                if (portfolioHistory.periodStart.length) {
                    portfolioHistory.periodStart = portfolioHistory.periodStart[0];
                    portfolioHistory.percentChange = history.reduce((sum, stock) => sum + stock.percentChange, 0) / history.length;
                }
                return ({
                    ...portfolioHistory,
                    data:
                    {
                        historical: acc
                    }
                });
            });
        }
        setUpdate(prev => prev + 1);
    }

    return (
        <div className="backtest">
            <Header pageName={"Backtest"} />
            <Grid container spacing={2} justifyContent="center" p={2}>
                <Grid item xs={10}>
                    <Paper className="portfolio">
                        <Typography variant="h6">Your Portfolio</Typography>
                        <LocalizationProvider dateAdapter={DateAdapter}>
                            <DatePicker
                                label="Select a date for your backtest to start"
                                value={date}
                                minDate={dayjs('12-08-2016')}
                                maxDate={dayjs('11-30-2021')}
                                onChange={handleDateChange}
                                renderInput={(params) => <TextField {...params} style={{ width: '100%' }} />}
                            />
                        </LocalizationProvider>
                        <Typography mt={2} textAlign="center">Your Stocks</Typography>
                        <Box display="flex" justifyContent="center">
                            {
                                tickers.map((o, i) => (<Chip sx={{ mx: 1 }} key={i} label={o} />))
                            }
                        </Box>
                        <Box mt={2}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                >
                                    <Typography>Detailed Stock Price Info</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {
                                        history.map((o, i) => {
                                            return <Stock key={i} historical={o} update={update} />
                                        })
                                    }
                                    {
                                        marketHistory ? (
                                            <Stock historical={marketHistory} update={update} market />
                                        ) : (<></>)
                                    }
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                        {
                            date ? (
                                <Box mt={2}>
                                    <StockTable market={marketHistory} stocks={history} update={update} portfolio={portfolioHistory} />
                                </Box>
                            ) : (
                                <Box mt={2}>
                                    <Typography variant="h4" textAlign="center">
                                        Select a date to view your portfolio's performance!
                                    </Typography>
                                </Box>
                                )
                        }
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}
