import axios from "axios";

export function getStockHistory(ticker) {
    const url = `https://financialmodelingprep.com/api/v3/historical-price-full/${ticker}?apikey=8c427788fd21aacbad8b00092be95301`
    return new Promise((res, rej) => {
        axios.get(url).then(result => {
            let data = result.data;
            if (data.historical) {
                data.historical.forEach(o => o.date = new Date(o.date));
            }
            res(data);
        }).catch(((e) => {
            rej();
        }));
    });
}

export function getMarketHistory() {
    const url = `https://financialmodelingprep.com/api/v3/historical-price-full/%5EGSPC?apikey=8c427788fd21aacbad8b00092be95301`;
    return new Promise((res, rej) => {
        axios.get(url).then(result => {
            let data = result.data;
            if (data.historical) {
                data.historical.forEach(o => o.date = new Date(o.date));
            }
            res(data);
        }).catch(((e) => {
            rej();
        }));
    });
}