import React, { useState } from 'react'
import { Container, Typography, Grid, Button, Paper } from '@mui/material'
import ButtonSwitch from '../components/ButtonSwitch'
import "./../styles/css/Home.css"
import Header from '../components/Header';
import { useNavigate } from 'react-router';

export default function Home() {
    const [form, setForm] = useState({ asset: "", items: "", test: "" });

    const navigate = useNavigate();

    const update = (key, val) => {
        form[key] = val;
        setForm(form);
    }

    return (
        <div className="home-container">
            <Header pageName="Welcome!" />
            <Grid container className="form" spacing={2} justifyContent="center">
                <Grid item xs={12}>
                    <ButtonSwitch
                        prompt="To get started, select an asset class:"
                        first={"Stocks"}
                        second={"Cryptocurrency"}
                        update={update}
                        id={"asset"}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ButtonSwitch
                        prompt="I’m going to be testing..."
                        first={"A Selection Strategy"}
                        second={"My Own Porfolio"}
                        update={update}
                        id={"items"}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ButtonSwitch
                        prompt="I want to run a..."
                        first={"Backtest"}
                        second={"Monte Carlo Simulation"}
                        update={update}
                        id={"test"}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Paper elevation={5} className="button-wrapper">
                        <Button className="submit-button" onClick={() => navigate('builder')}>Next</Button>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}
