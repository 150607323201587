import { createTheme, responsiveFontSizes } from "@mui/material";

export let theme = createTheme({
    palette: {
        primary: {
            main: '#002147'
        },
    },
    typography: {
        fontFamily: [
          'Alegreya Sans SC',
          'sans-serif',
        ].join(',')
    }
})

theme = responsiveFontSizes(theme);