import React from 'react'
import "./../styles/css/Header.css"
import { Typography, Box } from '@mui/material'
import { useNavigate } from 'react-router'

export default function Header({ pageName }) {
    const navigate = useNavigate();
    return (
        <div className="header">
            <Typography variant="h1" onClick={() => navigate('/')}>
                {pageName}
            </Typography>
            <Box className="logo">
                <Typography variant="h1">
                    finance.regress.it
                </Typography>
                <Typography variant="overline" className="subtitle">
                    Powered by FMP
                </Typography>
            </Box>
        </div>
    )
}
