import React, { useState } from 'react'
import { Paper, Grid, Typography } from '@mui/material';

export default function ButtonSwitch({ first, second, prompt, id, update }) {
    const [selected, setSelected] = useState(first);

    const updateSelected = (val) => {
        setSelected(val);
        update(id, val);
    }

    return (
        <Grid container columnSpacing={4} rowSpacing={2} className="switch" justifyContent="space-between">
            <Grid item xs={12}>
                <Typography variant="h4" textAlign="center">
                    {prompt}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Paper
                    elevation={10}
                    className={`option ${selected === first ? "selected" : ""}`}
                    onClick={() => updateSelected(first)}
                >
                    <Typography variant="h5" color="white">
                        {first}
                    </Typography>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Paper
                    elevation={10}
                    className={`option ${selected === second ? "selected" : ""}`}
                    onClick={() => updateSelected(second)}
                >
                    <Typography variant="h5" color="white">
                        {second}
                    </Typography>
                </Paper>
            </Grid>
        </Grid>
    )
}
