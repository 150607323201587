import React, { useEffect, useState } from 'react'
import { Box, Typography, Table, TableRow, TableCell, TableBody } from '@mui/material'
import StockChart from './StockChart';

export default function Stock({ historical, update }) {
    const { symbol, data, periodStart, periodEnd } = historical;
    const [percentChange, setPercentChange] = useState(0);

    useEffect(() => {
        if (periodEnd && periodStart) {
            setPercentChange((parseFloat(periodEnd.close) / parseFloat(periodStart.close) - 1) * 100);
        }
    }, [update])

    return (
        <Box mt={2}>
            <Typography variant="h5">
                {symbol}
            </Typography>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell><strong>Last Close:</strong> {data.historical[0].close}</TableCell>
                        <TableCell><strong>Period Change:</strong> {percentChange.toFixed(2)}%</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <StockChart symbol={symbol} data={data.historical} />
        </Box>
    )
}
