import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material"
import Home from "./pages/Home";
import { theme } from "./styles/themes/themes";
import Portfolio from "./pages/Portfolio";
import Backtest from "./pages/Backtest";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/builder" element={<Portfolio />} />
          <Route exact path="/backtest/:stocks" element={<Backtest />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
