import React from 'react'
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Box } from '@mui/material';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export default function StockChart({ symbol, data, market }) {
    data = Array(...data).reverse();
    const labels = data.map(o => o.label);
    const datasets = [
        {
            label: symbol,
            data: data.map(o => o.close),
            borderColor: 'rgb(0, 33, 71)',
            backgroundColor: 'rgba(0, 33, 71, 0.5)',
            borderWidth: .8,
            pointRadius: 1
        }
    ];

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: false,
            },
        },
        scales:{
            xAxes: {
                display: false //this will remove all the x-axis grid lines
            },
            y: {
                ticks: {
                    // Include a dollar sign in the ticks
                    callback: function(value) {
                        return (!market ? '$' : '') + value;
                    }
                }
            }
        }
    };

    return (
        <Box>
             <Line height="40px" options={options} data={{ labels, datasets }} />
        </Box>
    )
}
