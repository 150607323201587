import React, { useState, useEffect } from 'react'
import Header from '../components/Header'
import './../styles/css/PortfolioBuilder.css'
import { Grid, Typography, Paper, Table, TableHead, TableCell, TableRow, TableBody, IconButton, Input, Box, Button, TextField } from '@mui/material'
import { Close } from '@mui/icons-material'
import axios from 'axios'
import { useNavigate } from 'react-router'

export default function Portfolio() {
    const [stocks, setStocks] = useState([]);
    const [portfolio, setPortfolio] = useState([]);
    const [query, setQuery] = useState("");
    const [result, setResults] = useState([]);

    const navigate = useNavigate();

    const getStocks = () => {
        let url = 'https://financialmodelingprep.com/api/v3/stock/list?apikey=8c427788fd21aacbad8b00092be95301';
        return new Promise((res, rej) => {
            axios.get(url)
                .then(result => res(result.data))
                .catch(err => rej(err));
        })
    }

    useEffect(() => {
        getStocks().then(res => setStocks(res))
        return () => {
            setStocks([]);
            setPortfolio([]);
        }
    }, []);

    const handleRemove = (symbol) => {
        setPortfolio(portfolio.filter(stock => stock.symbol !== symbol));
    }

    const handleAdd = (stock) => {
        if (!portfolio.includes(stock)) {
            setPortfolio(prev => [...prev, stock]);
        }
    }

    const handlePress = () => {
        if (stocks) {
            navigate('/backtest/' + portfolio.map(o => o.symbol).join(','))
        }
    }

    const handleQueryChange = (e) => {
        const { value } = e.target;
        setQuery(value);
        if (value) {
            setResults(stocks.filter(({ symbol, name }) =>
                symbol.toLowerCase().includes(value.toLowerCase()) ||
                name.toLowerCase().includes(value.toLowerCase())
            ).slice(0, 5))
        } else {
            setResults([]);
        }
    }

    return (
        <>
            <Header pageName={"Build a Portfolio"} />
            <div className="portfolio-builder">
                <div className="portfolio-builder-container">
                    <Grid container spacing={4} justifyContent="center">
                        <Grid item md={6} sm={12}>
                            <Typography>
                                Use this search bar too search stock symbols
                            </Typography>
                            <Input
                                fullWidth
                                placeholder="Search stocks"
                                value={query}
                                onChange={handleQueryChange}
                            />
                            {
                                result.map((o, i) => {
                                    return (
                                        <Box className="search-result" key={i} onClick={() => handleAdd(o)}>
                                            <Typography variant="h6">{o.symbol}</Typography>
                                            <Typography variant="overline">{o.name}</Typography>
                                        </Box>
                                    )
                                })
                            }
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Paper className="current-stocks" elevation={5} maxHeight>
                                <Typography variant="h5" color="black">
                                    Your Portfolio<br></br>
                                </Typography>
                                <Typography variant="subtitle" color="gray" textAlign="center">
                                    Use this to create a mock portfolio. To add stocks, simply search and press add on the stocks that you would like to add. To remove stocks press the X to the right of the ticker in this box.
                                </Typography>
                                <Table size="small" className="stocks">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Symbol</TableCell>
                                            <TableCell>Company</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            portfolio.map((o, i) => (
                                                <TableRow>
                                                    <TableCell>{o.symbol}</TableCell>
                                                    <TableCell>{o.name}</TableCell>
                                                    <TableCell>
                                                        <IconButton onClick={() => handleRemove(o.symbol)}>
                                                            <Close />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            <Paper elevation={5}>
                                <Button onClick={handlePress} style={{ width: '100%' }}>Back Test</Button>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    )
}
