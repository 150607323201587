import React, { useEffect } from 'react'
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'

export default function StockTable({ stocks, market, portfolio, update }) {
    useEffect(() => {
        console.log(stocks);
    }, [update]);

    if (!portfolio.data || !stocks.length || !stocks[0].periodStart) {
        return <></>
    }

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell></TableCell>
                    {
                        stocks.map((o,i) => (
                            <TableCell key={i}>{o.symbol}</TableCell>
                        ))
                    }
                    <TableCell>Your Portfolio</TableCell>
                    <TableCell>Market</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>Starting Value</TableCell>
                    {
                        stocks.map((o,i) => (
                            <TableCell key={i}>${o.periodStart ? o.periodStart.close : 0}</TableCell>
                        ))
                    }
                    <TableCell>{}</TableCell>
                    <TableCell>{market.periodStart ? market.periodStart.close : 0}</TableCell>
                </TableRow>
                <TableRow key="ending">
                    <TableCell>Ending Value</TableCell>
                    {
                        stocks.map((o, i) => (
                            <TableCell key={i}>${o.periodEnd ? o.periodEnd.close : 0}</TableCell>
                        ))
                    }
                    <TableCell>{}</TableCell>
                    <TableCell>{market.periodEnd ? market.periodEnd.close : 0}</TableCell>
                </TableRow>
                <TableRow key="percent">
                    <TableCell>Percent Change</TableCell>
                    {
                        stocks.map((o, i) => (
                            <TableCell key={i}>{(o.percentChange || 0).toFixed(2)}%</TableCell>
                        ))
                    }
                    <TableCell>{(portfolio.percentChange || 0).toFixed(2)}%</TableCell>
                    <TableCell>{(market.percentChange || 0).toFixed(2)}%</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
}
